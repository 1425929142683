/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="wrapper">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <div
        className="page-body"
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main >{children}</main>
        </div>
      
      <footer
      className="page-footer"
        style={{
          margin: `5rem 0rem 2rem 0rem`,
          color: `#B89450`,
          textTransform: `uppercase`,
          fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
          fontWeight: 500,
          fontSize: `0.6em`,
          letterSpacing: `0.25em`,
          paddingBottom: `24px`,
        }}
      >
        <div>© {new Date().getFullYear()} Al Shakarchi Bakery &amp; Sweets.</div> 
        <div>All rights reserved.</div>
      </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
